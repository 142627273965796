/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

jQuery.expr[":"].Contains = jQuery.expr.createPseudo(function(arg) {
    return function( elem ) {
        return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.btn-search, .navbar-form .close-search').on('click',function(e){
          e.preventDefault();
          $('.btn-search').toggleClass('active');
          $('.navbar-form').toggleClass('active');
        });
        $('.btn-map, .state-cover, .state-map').on('click',function(e){
          e.preventDefault();
          $('.state-select').toggleClass('active');
          $('body').toggleClass('state-selected');
        });
        $('.search article .close-search').on('click',function(e){
          e.preventDefault();
          $(this).hide();
          $('.search article .form-control').val('');
        });
        $('.secondary-nav').sticky({
          topSpacing: 0,
          responsiveWidth: true
        });
        if( !$('.secondary-nav').length ) {
          $('.banner').sticky({
            topSpacing: 0,
            responsiveWidth: true
          });
        }
        $('#nav-primary .nav > li.menu-item-has-children, #nav-primary .nav > li.has-drop').each(function(index, el) {
          var elem = $(this);
          var timer;
          elem.prepend('<span></span>');
          var arrow = elem.find('span');
          if( $(window).width() < 768 ) {
            arrow.on('click',function(e){
              e.preventDefault();
              if( elem.hasClass('hover') ) {
                elem.toggleClass('hover');
              }else{
                $('#nav-primary .hover').removeClass('hover');
                elem.addClass('hover');
              }
            });
          }else{
            elem.mouseover(function(){
              if( !$('#nav-primary li').hasClass('hover') || elem.hasClass('hover') ) {
                elem.addClass('hover');
                clearTimeout(timer);
              }
            }).mouseout(function() {
              timer = setTimeout(function(){
                elem.removeClass('hover');
              },300);
            }).mouseenter(function(){
              setTimeout(function(){
                if( !$('#nav-primary li').hasClass('hover') && !elem.hasClass('hover') ) {
                  elem.addClass('hover');
                  clearTimeout(timer);
                }
              },300);
            });
          }
        });

        $('.matchHeight').each(function() {
          $(this).find('.matchItem').matchHeight();
        });

        /* Contact Info */
        $('.contact-tabs').each(function(index, el) {
          var elem = $(this);
          var button = elem.find('button');
          var drop = elem.find('.dropdown-menu');
          drop.find('a').on('click', function(e) {
            e.preventDefault();
            drop.find('li').removeClass('hidden').removeClass('active');
            $(this).tab('show');
            $(this).parent().addClass('hidden');
            button.find('.selected').html($(this).html());
          });
        });
        /* ********** */

        $('#select-state').on('change',function(){
          window.location = '/' + $(this).find('option:selected').val() + '/';
        });
        $('.has-drop a, .menu-item-has-children a').on('click', function(e) {
          $('.btn-search, .navbar-form, .btn-map, .map-select, .state-map').removeClass('active');
        });
        $('.glossary .glossary-close').on('click',function(){
          var gloss = $(this).parents('.glossary');
          var list = gloss.find('.list');
          gloss.find('input').val('');
          gloss.removeClass('onsearch');
          list.find('h3,hr,li').show();
        });
        $('.glossary input').on('keyup', function() {
          var key = $(this).val();
          var gloss = $(this).parents('.glossary');
          var list = gloss.find('.list');
          list.find('li').hide();
          list.find('li:Contains(' + key + ')').show();
          if( key.length > 0 ) {
            gloss.addClass('onsearch');
            list.find('h3,hr').hide();
          }else{
            gloss.removeClass('onsearch');
            list.find('h3,hr').show();
          }
        });
        $('.fancybox').fancybox({
          padding : 0,
          helpers:  {
            title : {
              type : 'over'
            }
          }
        });
        $('.fancybox-media').fancybox({
          openEffect  : 'none',
          closeEffect : 'none',
          helpers : {
            media : {}
          }
        });
        var counter_way = $('.counter').waypoint({
          handler: function(direction) {
            $('.counter').each(function(index, el) {
              var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',');
              var counter = $(this).attr('data-count');
              $(this).animateNumber({ 
                number: counter, 
                numberStep: comma_separator_number_step,
                easing: 'easeInQuad',
                },2000);
            });
            this.destroy();
          },
          offset: '80%'
        });

        var piechart_way = $('.piechart').waypoint({
          handler: function(direction) {
          var ctx = $('.piechart canvas');
          var myChart = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
              legend: { display: false },
              elements: { 
                arc: {
                  borderColor: '#f1f1f1'
                }
              }
            }
          });
            this.destroy();
          },
          offset: '80%'
        });

        $(document).bind('gform_page_loaded', function(){
          $('.process').addClass('hide');
        });
        $(document).bind('gform_post_render', function(){
          $('.gfield_radio').each(function(index, el) {
            var list = $(this);
            $(this).find('input[type=text]').attr({'pattern':'[0-9]{10}','type':'number','placeholder':'Or other amount?','min':1}).addClass('form-control');
            $(this).find('label,input[type=number]').on('click', function(e) {
              list.find('label').removeClass('active');
              $(this).addClass('active');
            });
            $(this).find('input[type=radio]:checked').parent().find('label').addClass('active');
          });
          $('.gfield_checkbox').each(function(index, el) {
            var list = $(this);
            $(this).find('label').on('click', function(e) {
              $(this).toggleClass('active');
            });
            $(this).find('a').on('click', function(e) {
              e.stopPropagation();
            });
            $(this).find('input:checked').parent().find('label').addClass('active');
          });

          /* Donate Specifics */
          $('.donate-step li').removeClass('active');
          var money = $('#gform_wrapper_7 .select-box input[type=radio]:checked').val();
          if( $('#gform_wrapper_7 .select-box input[type=radio]:checked').val() === 'gf_other_choice' ) {
            money = $('#gform_wrapper_7 .select-box input[type=number]').val();
          }
          var cont = '';
          var cont2 = '';
          if( $('#gform_wrapper_7 .true_false input[type=checkbox]:checked').val() ) {
            cont = ' <strong>monthly</strong>';
            cont2 = ' monthly';
          }
          $('#gform_submit_button_7').on('click',function(){
            $('.process').removeClass('hide');
          });
          $('#gform_submit_button_7').removeClass('btn-1').addClass('btn-6');
          $('#input_7_14_1_label').append('<i class="fa fa-lock"></i>');
          $('#gform_wrapper_7 .gform_page:eq(1), #gform_wrapper_7 .gform_page:eq(2)').prepend('<div class="info">You are donating <strong>$' + money + '</strong> to Variety' + cont + '</div>' );
          $('#gform_submit_button_7').html( $('#gform_submit_button_7').html() + ' $' + money + cont2 );
          $('#gform_wrapper_7 .gform_page').each(function(index, el) {
            if( $(this).css('display') !== 'none' ){
              $('.donate-step li:eq('+ index +')').addClass('active');
              return false;
            }else{
              $('.donate-step li:eq('+ index +')').addClass('active');
            }
          });
          $('#input_7_13_other').attr('placeholder', 'Other');
          $('#gform_wrapper_7 .info').on('click',function(){
            $('#gform_wrapper_7 .gform_previous_button').trigger('click');
          });
          $(window).resize(function(event) {
            if( $(this).width() < 479 ) {
              $('#input_7_13_other').attr('placeholder', 'Other');
            }
          });
          /* ********** */          
        });
        $('.matchHeight').matchHeight();
        $('.membership-box').each(function(index, el) {
          var pad = $(this).find('.pads');
          var but = $(this).find('.read-more');
          but.on('click',function(e){
            e.preventDefault();
            pad.toggleClass('active');
          });
        });
        $('.stories-swiper .swiper-container').swiper({
          preventClicks: false,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
        });
        $('.dash-timeline-swiper').each(function(index, el) {
          $active = $(this).find('li[data-active="1"]').index();
          var timelineSwipe = $(this).find('.swiper-container').swiper({
            slidesPerView: 7,
            slidesPerGroup: 1,
            preventClicks: false,
            nextButton: $(this).find('.swiper-button-next'),
            prevButton: $(this).find('.swiper-button-prev'),
            breakpoints: {
              480: {
                slidesPerView: 2,
              },
              540: {
                slidesPerView: 3,
              },
              767: {
                slidesPerView: 4,
              },
              979: {
                slidesPerView: 5,
              },
              1199: {
                slidesPerView: 6,
              }
            },
            onInit: function(swiper){
              swiper.slideTo($active);
            }
          });
        });
        $('.gallery-slide').each(function(index, el) {
          var galleryThumbs;
          var galleryTop = $(this).find('.gallery-large .swiper-container').swiper({
              nextButton: $(this).find('.swiper-button-next'),
              prevButton: $(this).find('.swiper-button-prev'),
              slidesPerView: '1',
              loop: true,
              preventClicks: false,
              loopAdditionalSlides: 20,
          });
          galleryThumbs = $(this).find('.gallery-thumbs').swiper({
              spaceBetween: 10,
              slidesPerView: 4,
              centeredSlides: true,
              slideToClickedSlide: true,
              loop: true,
              loopAdditionalSlides: 20,
              breakpoints: {
                480: {
                  slidesPerView: 3,
                }
              }
          });
          galleryTop.params.control = galleryThumbs;
          galleryThumbs.params.control = galleryTop;
        });
        if( $('.au-map').length ) {
          var aumap = $('.au-map').vectorMap({
            map: 'au_mill',
            backgroundColor: 'transparent',
            zoomOnScroll: false,
            panOnDrag: false,
            zoomButtons: false,
            regionStyle: {
              initial: {
                fill: '#aaaaaa',
                stroke: '#efefef',
                "stroke-width": 8,
              },
              hover: {
                fill: '#ed2c23',
              }
            },
            regionLabelStyle: {
              initial: {
                'font-family': 'Roboto',
                'font-size': '16',
              }
            },
            onRegionClick: function(event, code){
              var url = code.split('-');
              window.location = '/' + url[1].toLowerCase() + '/';
            }
          });
        }

        /* BASH */
        $('.autosubmit').each(function(index, el) {
          var form = $(this);
          $(this).find('select').on('change',function(){
            form.submit();
          });
        });

        /*new Maplace({
          locations: map_location,
          controls_on_map: false
        }).Load();*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'financials': {
      init: function() {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
